import React from "react";
import { PageTitle } from "./PageTitle";

export const Success = () => {
  return (
    <section className="px-2 md:px-8 xl:px-0 max-w-7xl mx-auto mb-24 md:mb-32 xl:mb-40">
      <PageTitle title="お問い合わせありがとうございます" />
      <div className="bg-white box-shadow py-16 px-4 rounded-news bg-opacity-80 md:pt-20 lg:pt-24">
        <div className="px-2 max-w-2xl mx-auto lg:px-0">
          <p className="text-sm tracking-wider leading-loose">
            この度はお問い合わせメールをお送りいただきありがとうございます。
            <br />
            後ほど、担当者よりご連絡をさせていただきます。
            <br />
            今しばらくお待ちくださいますようよろしくお願い申し上げます。
            <br />
            <br />
            なお、しばらくたっても当社より返信、返答がない場合は、
            <br />
            お客様によりご入力いただいたメールアドレスに誤りがある場合がございます。
            <br />
            メールアドレスをご確認の上、もう一度フォームよりお問合せ頂きますようお願い申し上げます。
            <br />
          </p>
        </div>
      </div>
    </section>
  );
};
