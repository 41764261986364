import React from "react";
import { PageTitle } from "./PageTitle";

export const Terms = () => {
  return (
    <section className="px-2 md:px-8 xl:px-0 max-w-7xl mx-auto mb-24 md:mb-32 xl:mb-40">
      <PageTitle title="利用規約" />
      <div className="bg-white box-shadow py-16 px-4 rounded-news bg-opacity-80 md:py-20 lg:py-24">
        <div className="max-w-3xl mx-auto">
          <p className="terms-text">
            この利用規約（以下「本規約」いいます。）は、株式会社プリベント（以下「当社」といいます。）が運営するアプリ（東京横丁）により提供するサービスの利用条件等を定めるものです。
          </p>
          <h2 className="terms-title">第1章　総則</h2>
          <h3 className="terms-subtitle">第1条（定義）</h3>
          <p className="terms-text">
            本規約における用語の定義は以下のとおりとします。
          </p>
          <ol className="terms-subnumbering">
            <li className="terms-text">
              「お客様」とは、本サービスの利用者をいいます。
            </li>
            <li className="terms-text">
              「本アプリ」とは、本サービスに関して当社が提供するアプリケーションソフト「東京横丁」をいいます。
            </li>
            <li className="terms-text">
              「本ウェブサイト」とは、本サービスに関する情報等を掲載した当社が運営するウェブサイト（https://tokyo-yokocho.jp/）をいいます。
            </li>
            <li className="terms-text">
              「本サービス」とは、無償、有償を問わず、当社が本アプリ上で提供する一切のサービスをいいます。
            </li>
            <li className="terms-text">
              「本無料サービス」とは、本サービスのうち、無償で利用可能なサービスをいい、第4条第1項第1号に定めるものをいいます。
            </li>
            <li className="terms-text">
              「本有料サービス」とは、本サービスのうち、有償（有料課金）の対象となるサービスをいい、第4条第1項第2号に定めるものをいいます。
            </li>
            <li className="terms-text">
              「本プライバーポリシー」とは、当社が本アプリにおいて掲載する「アプリケーション・プライバシーポリシー」をいいます。
            </li>
            <li className="terms-text">
              「本有料サービス利用契約」とは、第54条第2項に基づき成立するお客様と当社の間の、本有料サービスの利用に関する契約をいいます。
            </li>
            <li className="terms-text">
              「個人情報」とは、個人情報の保護に関する法律第2条第1項に定める個人情報をいいます。
            </li>
            <li className="terms-text">
              「反社会的勢力」とは、暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団、その他これらに準ずる者をいいます。
            </li>
          </ol>
          <h2 className="terms-title">第2章　会員登録</h2>
          <h3 className="terms-subtitle">第2条（利用資格、会員登録）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              本サービスは、18歳以上の方が利用できるものとします。
            </li>
            <li className="terms-text">
              本サービスを利用されるお客様は、事前に会員登録を行うものとします。
            </li>
            <li className="terms-text">
              お客様は、会員登録される場合、事前に本規約及び本プライバシーポリシーに同意した上で、本アプリ上で所定の情報を入力し、当社に申込みを行うものとします。
            </li>
            <li className="terms-text">
              当社は、お客様による会員登録の申込み時に、お客様が18歳以上であることの確認を、お客様の運転免許証その他お客様の年齢又は生年月日を証する書面の画像の送信を受ける方法など当社指定の方法により行うものとし、お客様はこれに従って必要な情報を当社に提供するものとします。
            </li>
            <li className="terms-text">
              前二項に基づくお申込みに対し当社が承諾した上で会員登録を完了した旨の通知をお客様に行った場合、当該通知の時点でお客様は会員になるものとします。
            </li>
            <li className="terms-text">
              当社は、お客様が以下の各号に該当する場合、会員登録を拒否できるものとします。
              <ol className="terms-subnumbering">
                <li className="terms-text">
                  第1項の要件を充足しない者である場合
                </li>
                <li className="terms-text">
                  成年被後見人であり、本サービスを利用することについて成年後見人の同意を得ていない場合、又は被保佐人若しくは被補助人であり、本サービスを利用するには保佐人若しくは補助人の同意が必要とされているにもかかわらずこれを得ていない場合
                </li>
                <li className="terms-text">反社会的勢力である場合</li>
                <li className="terms-text">
                  過去に当社との契約に違反した者である場合又は本規約に基づく会員資格を喪失した者である場合
                </li>
                <li className="terms-text">
                  過去に本サービスを利用して違法行為、第三者の権利を侵害する行為、第三者に迷惑を生じさせる行為その他これらに準じる行為を行ったことがある又はその疑いがある場合
                </li>
                <li className="terms-text">
                  第三者に本サービスを利用させる目的で会員登録を行おうとしている場合
                </li>
                <li className="terms-text">
                  前項に定めるもののほか、本サービスを提供することが不適切であると当社が判断した場合
                </li>
              </ol>
            </li>
            <li className="terms-text">
              当社は、会員登録の申込を行ったお客様について一定の審査が必要と判断した場合、お客様に必要な資料その他の情報の提供を求める場合があります。その場合、お客様は、速やかに指定された資料その他の情報を当社に提供するものとします。
            </li>
            <li className="terms-text">
              当社は、第4項に基づきお客様の会員登録を承諾しない場合、お客様からその理由について問い合わせがあったとしても、当該理由について回答する義務を負わないものとします。
            </li>
            <li className="terms-text">
              お客様は、当社に登録した情報に変更があった場合、速やかに当社に変更内容を申し出るものとします。
            </li>
            <li className="terms-text">
              会員の退会を希望するお客様は、当社にその旨申し出るものとします。但し、本有料サービス利用契約を締結しているお客様が退会を行う場合、同時に当社に対して本利用契約本有料サービス利用契約の解約の申入れを行うものとし、本利用契約本有料サービス利用契約の終了をもって退会の効果が発生するものとします。なお、お客様は、退会により一切の本サービスが利用できなくなるため、当社による退会手続が完了した後に本サービスの利用を希望する場合、再度会員登録を行う必要があるものとします。
            </li>
          </ol>
          <h3 className="terms-subtitle">第3条（年齢確認に係る非保証）</h3>
          <p className="terms-text">
            当社は、お客様の年齢の確認を、第2条第4項に基づき、会員登録時のお客様が送信される画像等の情報の自己申告に依拠して行うものであるため、他のお客様が18歳又は20歳以上であることを保証するものではありません。
          </p>
          <h2 className="terms-title">
            第3章　本サービスの利用、本有料サービス利用契約の成立・条件
          </h2>
          <h3 className="terms-subtitle">第4条（本サービスの内容）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              本サービスの内容は、本アプリの利用を通じてお客様が一定の距離内にいる他のお客様やそのグループと連絡を取り合って、食事その他健全なコミュニケーションを図る目的で会う機会を提供するものであり、機能詳細は本アプリ内に表示するとおりとします。なお、お客様が、各月において本アプリで利用可能な、お客様の所在地を示す符号（以下「ピン」といいます。）の本数の制限の有無により、以下のとおり本無料サービス及び本有料サービスに区別されます。ピンは、一定時間内にお客様が他のお客様との連絡を取り合うことを可能にする本アプリ上の機能であり、その詳細については本アプリにおける説明をご覧下さい。
              <ol className="terms-subnumbering">
                <li className="terms-text">
                  本無料サービス
                  <br />
                  各月において本アプリで利用可能なピンの数：0本
                </li>
                <li className="terms-text">
                  本有料サービス
                  <br />
                  各月において本アプリで利用可能なピンの数：制限なし
                </li>
              </ol>
            </li>
            <li className="terms-text">
              会員登録されたお客様は本無料サービスを、本規約に従って無償にて利用することができます。また、会員登録されたお客様は、当社との間で本有料サービス利用契約を締結することにより、本有料サービスを利用することができます。
            </li>
            <li className="terms-text">
              本有料サービスは、利用可能期間に応じ、以下のプランから構成されます。
              <div className="table-privacy border text-sm lg:text-base mb-2">
                <div className="flex border-b">
                  <div className="border-r w-1/3">
                    <span className="block text-center p-2">プラン</span>
                  </div>
                  <div className="w-2/3">
                    <span className="block text-center p-2">
                      本有料サービスの利用可能期間
                    </span>
                  </div>
                </div>
                <div className="flex border-b">
                  <div className="border-r w-1/3">
                    <span className="block px-4 py-2">単月プラン</span>
                  </div>
                  <div className="w-2/3">
                    <span className="block px-4 py-2">
                      本有料サービス利用契約の成立日の翌月1日から1か月間
                    </span>
                  </div>
                </div>
                <div className="flex border-b">
                  <div className="border-r w-1/3">
                    <span className="block px-4 py-2">3か月プラン</span>
                  </div>
                  <div className="w-2/3">
                    <span className="block px-4 py-2">
                      本有料サービス利用契約の成立日の翌月1日から3か月間
                    </span>
                  </div>
                </div>
                <div className="flex border-b">
                  <div className="border-r w-1/3">
                    <span className="block px-4 py-2">6か月プラン</span>
                  </div>
                  <div className="w-2/3">
                    <span className="block px-4 py-2">
                      本有料サービス利用契約の成立日の翌月1日から6か月間
                    </span>
                  </div>
                </div>
                <div className="flex border-b">
                  <div className="border-r w-1/3">
                    <span className="block px-4 py-2">12か月プラン</span>
                  </div>
                  <div className="w-2/3">
                    <span className="block px-4 py-2">
                      本有料サービス利用契約の成立日の翌月1日から12か月間
                    </span>
                  </div>
                </div>
                <div className="flex border-b">
                  <p className="py-2 px-4">
                    ※本有料サービスが更新された場合には、利用可能期間も各プランに対応する期間（1か月、3か月、6か月又は12か月間）分更新されるものとします。
                  </p>
                </div>
              </div>
            </li>
            <li className="terms-text">
              当社は、本サービスを利用するお客様に対し、本規約の定める条件に従い、本アプリの譲渡不能で非独占的な使用権を許諾します。
            </li>
            <li className="terms-text">
              当社は、日本語により本アプリ及び本サービスを提供するものとします。
            </li>
            <li className="terms-text">
              お客様は、本サービスを受けるに当たり、本アプリ又は本ウェブサイトにおいて表示される利用方法又は注意事項を全て遵守するものとします。
            </li>
            <li className="terms-text">
              当社は、本サービスの内容を変更し又は本サービスを終了することがあるものとします。
            </li>
          </ol>
          <h3 className="terms-subtitle">
            第5条（本有料サービス利用契約の成立）
          </h3>
          <ol className="text-numbering">
            <li className="terms-text">
              お客様は、本有料サービスの利用を希望する場合、本アプリ上で当社が指定する方式に従い申込みを行うものとします。
            </li>
            <li className="terms-text">
              第1項に定める申込みに対して当社が承諾の上、受付が完了した旨の通知をお客様に行った場合、当該時点において、お客様及び当社との間で本規約に基づく本有料サービス利用契約が成立するものとします。
            </li>
          </ol>
          <h3 className="terms-subtitle">
            第6条（本有料サービス利用料金・決済方法）
          </h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              本有料サービスの利用料金は以下のとおりとします。
              <div className="table-privacy border text-sm lg:text-base mb-2">
                <div className="flex border-b">
                  <div className="border-r w-1/3">
                    <span className="block text-center p-2">プラン</span>
                  </div>
                  <div className="w-2/3">
                    <span className="block text-center p-2">料金</span>
                  </div>
                </div>
                <div className="flex border-b">
                  <div className="border-r w-1/3">
                    <span className="block px-4 py-2">単月プラン</span>
                  </div>
                  <div className="w-2/3">
                    <span className="block px-4 py-2">4,000円（税別）</span>
                  </div>
                </div>
                <div className="flex border-b">
                  <div className="border-r w-1/3">
                    <span className="block px-4 py-2">3か月プラン</span>
                  </div>
                  <div className="w-2/3">
                    <span className="block px-4 py-2">9,637円（税別）</span>
                  </div>
                </div>
                <div className="flex border-b">
                  <div className="border-r w-1/3">
                    <span className="block px-4 py-2">6か月プラン</span>
                  </div>
                  <div className="w-2/3">
                    <span className="block px-4 py-2">14,364円（税別）</span>
                  </div>
                </div>
                <div className="flex border-b">
                  <div className="border-r w-1/3">
                    <span className="block px-4 py-2">12か月プラン</span>
                  </div>
                  <div className="w-2/3">
                    <span className="block px-4 py-2">23,455円（税別）</span>
                  </div>
                </div>
              </div>
            </li>
            <li className="terms-text">
              お客様は、前項に定める当月分の本有料サービスの利用料について、App
              Store又はGoogle
              Playにおけるアプリ内決済の利用に登録しているクレジットカードの引落日をもって、消費税及び地方消費税を加算した金額を、クレジットカード払いの方法により当社に支払うものとします。
            </li>
          </ol>
          <h3 className="terms-subtitle">
            第7条（本有料サービス利用契約の有効期間）
          </h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              本有料サービス利用契約の有効期間は、本有料サービス利用契約成立日から起算して、当該本有料サービスの利用可能期間が満了する日までとします。但し、当該有効期間満了前日までにお客様又は当社のいずれか一方より更新を拒絶する旨の通知がなされない場合、当該本有料サービス利用契約は、当該有料サービスの利用期間と同じ期間、同様の条件をもって自動的に更新されるものとし、以後も同様とします。
            </li>
            <li className="terms-text">
              お客様は、本有料サービス利用契約の期間中、いつでも同契約を解約できるものとし、解約申入日をもって本有料サービス利用契約は終了するものとします。但し、お客様は当該本有料サービス利用契約の対象となる期間に係る利用料金の支払義務を免れないものとします。
            </li>
          </ol>
          <h3 className="terms-subtitle">第8条（再委託）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              当社は、本サービスに係る業務の全部又は一部を第三者に再委託することができるものとします。
            </li>
          </ol>
          <h2 className="terms-title">第4章　コンテンツ等の権利関係・責任</h2>
          <h3 className="terms-subtitle">第9条（権利の帰属）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              本アプリ中の表示及び本アプリを構成するプログラム等、並びに本アプリの本サービスにおいて当社が提供する情報及び動画、その他のコンテンツ及びお客様への一切の提供物（以下「本コンテンツ等」といいます。）の著作権（著作権法
              27条及び第
              28条に定める権利を含みます。）、特許権、商標権等の知的財産権その他一切の権利（以下「知的財産権等」といいます。）は、当社に帰属するものとします。
            </li>
            <li className="terms-text">
              本有料サービス利用契約は、本規約において明示的に許容された以外の態様によるお客様の本コンテンツ等の使用を許諾するものではありません。お客様は、当社の事前の書面による許諾を得ることなく、本コンテンツ等の翻案、編集、改変等を行い、第三者に使用させ、又は公開することはできず、いかなる理由によっても当社の知的財産権等を侵害し又はそのおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしないものとします。
            </li>
          </ol>
          <h2 className="terms-title">第5章　お客様の遵守事項等</h2>
          <h3 className="terms-subtitle">第10条（お客様の遵守事項等）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              お客様は本サービスの利用に当たり、以下の行為をしてはならないものとします。
              <ol className="terms-subnumbering">
                <li className="terms-text">
                  本サービスを受けるに当たりお客様に適用のある法律、政令、省令、条例、規則及び命令等に違反する行為
                </li>
                <li className="terms-text">
                  本規約、本プライバシーポリシーその他当社が定める規約、ガイドラインに違反する行為
                </li>
                <li className="terms-text">
                  お客様情報の登録若しくは変更又は本有料サービス利用契約の申込に際し、当社に虚偽の事実を申告する行為
                </li>
                <li className="terms-text">
                  自己の名義を用いて第三者に本サービスを利用させる行為（18歳未満の者に本サービスを利用させる行為を含みます。）
                </li>
                <li className="terms-text">
                  当社と関係のない団体、サービス、活動等への勧誘又は誘導を目的とする行為（政治活動及び宗教活動を目的とするものを含みます。）
                </li>
                <li className="terms-text">
                  不正な利益の取得を目的とする行為又はそれに準ずる行為
                </li>
                <li className="terms-text">
                  無限連鎖講及びマルチ商法又はそれに類する行為
                </li>
                <li className="terms-text">
                  営業行為、広告、その他営利を目的として本サービスを利用する行為
                </li>
                <li className="terms-text">
                  他人のクレジットカード、電話、銀行口座、電子マネー等を盗用して本サービスの提供を受けようとする行為、及び他のお客様の情報を不正使用する行為
                </li>
                <li className="terms-text">
                  他のユーザーの個人情報を収集、蓄積する行為又はこれらの準備行為
                </li>
                <li className="terms-text">
                  当社、他のお客様その他第三者の権利を侵害し又はこれらの者に迷惑、嫌がらせストーキング、差別若しくは誹謗中傷をする行為
                </li>
                <li className="terms-text">
                  犯罪的行為若しくは犯罪的行為に結びつく行為、売春・買春、援助交際若しくは児童ポルノに属する行為、公序良俗に反する行為、その他これらに類する行為又はそのおそれのある行為
                </li>
                <li className="terms-text">異性との交際を目的とする行為</li>
                <li className="terms-text">
                  性的交渉、その他猥褻な行為を目的とする行為
                </li>
                <li className="terms-text">
                  アダルトサイトに誘導する行為、及びアダルト関係の商品について関与させ又は紹介することを目的とする行為
                </li>
                <li className="terms-text">20歳未満の者に飲酒を促す行為</li>
                <li className="terms-text">
                  当社及び本サービスのイメージを損なう行為
                </li>
                <li className="terms-text">
                  当社の運営を妨げ又はそのおそれのある行為
                </li>
                <li className="terms-text">本規約に違反する行為</li>
                <li className="terms-text">その他当社が不適切と判断する行為</li>
              </ol>
            </li>
            <li className="terms-text">
              お客様は、本サービスを利用するに当たり、以下の事項を承諾するものとします。
              <ol className="terms-subnumbering">
                <li className="terms-text">
                  本サービスは、GPSを用いたお客様の位置情報を利用するものであることから、本サービスにおいてお客様のGPS機能をオンにした場合、お客様の位置情報が他のお客様に認識されること
                </li>
                <li className="terms-text">
                  お客様の位置情報が他のお客様に認識されないようにするには、お客様のGPS機能をオフにする必要があること、また、その場合にはお客様は他のお客様の位置情報も入手できず本サービスの効果を享受できないこと
                </li>
              </ol>
            </li>
            <li className="terms-text">
              本サービスを利用するに当たっては、お客様の責任と費用負担において、本アプリをダウンロードし、かつ本アプリを利用するために必要な通信手段、回線、機器、ソフトウェアその他一切の手段を準備及び維持するものとします。
            </li>
          </ol>
          <h2 className="terms-title">第6章　保証の否認</h2>
          <h3 className="terms-subtitle">第11条（本サービスに関する保証）</h3>
          <p className="terms-text">
            当社は、本サービスによる結果等、お客様に何ら成果、効用又は効能を保証するものではありません。当社は、お客様による本サービスの利用による結果について、一切の責任を負わないものとします。
          </p>
          <h2 className="terms-title">第7章　本サービスの中断等</h2>
          <h3 className="terms-subtitle">第12条（本サービスの一時的な中断）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              当社は、以下の各号のいずれかの事由に該当することにより本サービスの提供が合理的に困難になった場合には、お客様に事前に通知することなく、一時的に本サービスの全部又は一部の提供を中断することができるものとします。
              <ol className="terms-subnumbering">
                <li className="terms-text">
                  火災、停電その他の事由により、本サービスの提供に用いるサーバー、通信回線その他の設備の故障又は障害が発生したとき
                </li>
                <li className="terms-text">
                  本アプリその他本サービスの提供に用いるシステム（サーバー、通信回線、電源、それらを収容する建築物などを含む）の保守、点検、修理、変更が必要になったとき
                </li>
                <li className="terms-text">
                  地震、噴火、洪水、津波等の天災が発生したとき
                </li>
                <li className="terms-text">
                  戦争、変乱、暴動、騒乱、労働争議、感染症の蔓延等その他不可抗力の事象が発生したとき
                </li>
                <li className="terms-text">
                  当社と関係のない団体、サービス、活動等への勧誘又は誘導を目的とする行為（政治活動及び宗教活動を目的とするものを含みます。）
                </li>
                <li className="terms-text">
                  本サービスに関し法令又はそれに基づく措置を求められたとき
                </li>
                <li className="terms-text">前各号に準ずる事象が発生したとき</li>
              </ol>
            </li>
            <li className="terms-text">
              当社は、前項に基づく本サービスの中断に起因する一切の損害について、お客様及び第三者に対して一切責任を負わないものとします。
            </li>
          </ol>
          <h2 className="terms-title">第8章　一般条項</h2>
          <h3 className="terms-subtitle">第13条（本規約の変更）</h3>
          <p className="terms-text">
            当社は、本規約を変更する場合、変更内容に応じて一定の猶予期間を設けて変更後の規約の効力発生時期を定め、かつ、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を事前に本アプリ上又は当社が適切と判断する方法によりお客様に周知するものとします。当該効力発生時期に本規約の変更の効力が生じるものとします。
          </p>
          <h3 className="terms-subtitle">第14条（通知方法）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              当社は、お客様に通知及び連絡の必要があると判断した場合、お客様が登録した会員登録情報に記載されている電子メールアドレス、電話番号又は住所に対し、電子メール、電話又は郵便を用いて通知及び連絡を行います。
            </li>
            <li className="terms-text">
              当社からの通知及び連絡が不着又は遅延したことによって生じる損害について、当社の故意又は重過失がある場合を除き、当社は一切の責任を負いません。
            </li>
            <li className="terms-text">
              お客様が当社に通知、連絡、問合せをする必要が生じた場合、本アプリ又は本ウェブサイトに記載の窓口に対し、電子メール又は郵便をもって行うこととします。
            </li>
            <li className="terms-text">
              前項に基づきお客様から問合せがあった場合、当社は、お客様の本人確認を行うことができるものとします。また、問合せに対する回答方法（電子メール、電話、郵送等）については、その都度、当社が最適と考える方法を利用して回答することができるものとします。
            </li>
          </ol>
          <h3 className="terms-subtitle">第15条（損害賠償）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              当社は、本サービス又は本有料サービス利用契約に起因又は関連して生じるお客様の損害について、当社に故意又は重過失がある場合に賠償する責任を負うものとします。
            </li>
            <li className="terms-text">
              前項に基づき当社が賠償するお客様の損害は、お客様が現実に被った直接かつ通常の損害に限られるものとし、法律構成の如何を問わず、本有料サービス利用契約に基づいて当社がお客様から受領した利用料金（当該損害発生の原因が生じた日から過去1年以内のものに限ります。）の合計額を累積での上限額とします。
            </li>
          </ol>
          <h3 className="terms-subtitle">第16条（トラブル）</h3>
          <p className="terms-text">
            当社は、故意又は重過失がある場合を除き、本サービスを用いたお客様及び第三者（他のお客様を含みます。）の間のトラブル又はクレーム（以下「本トラブル等」といいます。）について、一切責任を負わないものとし、お客様の責任と費用負担によりこれを解決するものとします。お客様の責めに帰すべき事由による本トラブル等により当社が損害、損失又は費用を負担した場合、お客様は当社に対してこれを補償するものとします。
          </p>
          <h3 className="terms-subtitle">第17条（解除、会員資格の喪失）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              お客様が次のいずれかに該当する場合、当社は何らの催告を要することなく直ちに本有料サービス利用契約を解除し、かつ、お客様の会員資格を喪失させることができるものとします。
              <ol className="terms-subnumbering">
                <li className="terms-text">
                  本有料サービス利用契約（本規約第10条第1項を含みます。）に違反したとき
                </li>
                <li className="terms-text">
                  第2条第6項各号に定めるいずれかの事由があるとき
                </li>
                <li className="terms-text">
                  自らが振り出し若しくは引き受けた手形若しくは小切手が不渡りとなったとき又は支払停止若しくは支払不能の状態になったとき
                </li>
                <li className="terms-text">
                  第三者より仮差押え、仮処分、差押え、競売の申立てその他の強制執行又は公租公課の滞納処分を受けたとき
                </li>
                <li className="terms-text">
                  破産、民事再生その他法的倒産手続の開始申立てがあったとき
                </li>
                <li className="terms-text">
                  その他、本有料サービス利用契約を継続しがたい重大な事由が生じたとき
                </li>
              </ol>
            </li>
            <li className="terms-text">
              お客様は、前項各号のいずれかに該当した場合、当社からの通知により、本有料サービス利用契約に基づく一切の債務について期限の利益を失い、直ちに全ての債務を履行するものとします。
            </li>
          </ol>
          <h3 className="terms-subtitle">第18条（反社会的勢力）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              お客様は、自らが次の各号のいずれにも該当しないことを表明し、かつ将来にわたり該当しないことを確約します。
              <ol className="terms-subnumbering">
                <li className="terms-text">反社会的勢力であること</li>
                <li className="terms-text">
                  反社会的勢力が経営を支配していると認められる関係を有すること
                </li>
                <li className="terms-text">
                  反社会的勢力が経営に実質的に関与していると認められる関係を有すること
                </li>
                <li className="terms-text">
                  自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力を利用していると認められる関係を有すること
                </li>
                <li className="terms-text">
                  反社会的勢力に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること
                </li>
                <li className="terms-text">
                  役員又は経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有すること
                </li>
              </ol>
            </li>
            <li className="terms-text">
              お客様及び当社は、自ら又は第三者を利用して次の各号の一つにでも該当する行為を行わないことを確約します。
              <ol className="terms-subnumbering">
                <li className="terms-text">暴力的な要求行為 </li>
                <li className="terms-text">法的な責任を超えた不当な要求行為</li>
                <li className="terms-text">
                  取引に関して脅迫的な言動をし、又は暴力を用いる行為
                </li>
                <li className="terms-text">
                  風説を流布し、偽計若しくは威力を用いて他の当事者の信用を毀損し、又は他の当事者の業務を妨害する行為
                </li>
                <li className="terms-text">その他前各号に準ずる行為</li>
              </ol>
            </li>
            <li className="terms-text">
              お客様及び当社は、相手方が前各項に違反した場合は、催告を行うことなく直ちに本有料サービス利用契約を解除できるとともに、当該解除により被った損害の賠償を相手方に請求できるものとします。なお、この場合、本契約を解除した当事者は、当該解除により相手方の被った損害について賠償する責を負わないものとします。
            </li>
            <li className="terms-text">
              前項に基づき当社が本有料サービス利用契約を解除した場合、当社はお客様の会員資格を喪失させることができるものとします。
            </li>
          </ol>
          <h3 className="terms-subtitle">第19条（秘密保持）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              お客様及び当社は、本有料サービス利用契約期間中及び本有料サービス利用契約終了後2年間、本有料サービス利用契約に関して知り得た相手方に関する情報、並びに本有料サービス利用契約の存在及び内容（以下「秘密情報」といいます。）について、相手方の書面による事前の承諾がある場合を除き、これを第三者に対して開示せず、かつ本有料サービス利用契約の目的以外に利用しないものとします。但し、次に定めるものは秘密情報から除かれるものとします。
              <ol className="terms-subnumbering">
                <li className="terms-text">
                  相手方から開示を受けた時点で公知である情報
                </li>
                <li className="terms-text">
                  相手方から開示を受けた後、自己の責によらず公知となった情報
                </li>
                <li className="terms-text">
                  相手方から開示を受けた時点で既に適法に保有していた情報
                </li>
                <li className="terms-text">
                  秘密保持義務を負うことなく適法に第三者から取得した情報
                </li>
              </ol>
            </li>
            <li className="terms-text">
              前項にかかわらず、お客様及び当社は、法令に従い要求される場合、又は裁判所若しくは行政機関の要請により合理的に必要となる場合には、秘密情報を開示することができるものとします。また、当社は、本サービスに係る業務の再委託先に対して、本条に基づく当社の義務と同等の義務を課すことを条件に、秘密情報を開示することができるものとします。
            </li>
          </ol>
          <h3 className="terms-subtitle">第20条（個人情報保護）</h3>
          <p className="terms-text">
            当社は、本サービスの提供に際して取得したお客様に関する個人情報に関して個人情報の保護に関する法律及び本プライバシーポリシーを遵守します。
          </p>
          <h3 className="terms-subtitle">第21条（権利義務の譲渡禁止）</h3>
          <p className="terms-text">
            お客様は、当社の事前の書面による承諾がある場合を除き、第三者に対し、本有料サービス利用契約上のお客様の地位又は本有料サービス利用契約に基づく権利若しくは義務について、その全部又は一部を譲渡若しくは移転し、又は第三者のための担保に供する等一切の処分をすることができないものとします。
          </p>
          <h3 className="terms-subtitle">第21条（準拠法及び合意管轄）</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              本有料サービス利用契約は日本法を準拠法とし、日本法に従い解釈されるものとします。
            </li>
            <li className="terms-text">
              本有料サービス利用契約について紛争が生じた場合は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </li>
          </ol>
          <div className="mt-24 lg:mt-40 flex flex-col items-end">
            <span className="block text-sm lg:text-base tracking-wider">
              2022年6月24日制定
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
