import { Link } from "gatsby";
import React from "react";

export const TopBanner = () => {
  return (
    <section className="px-6 md:px-16 lg:px-24 xl:px-0 max-w-6xl mx-auto">
      <div className="relative w-full h-screen flex flex-col justify-center">
        <div className="">
          <h1 className="tracking-widest font-bold text-4xl mb-2 md:text-5xl xl:text-6xl">
            <span className="block">隠れた近所の</span>
            <span>名店を</span>
            <span className="leading-tight text-6xl md:text-7xl xl:text-8xl bg-clip-text text-transparent bg-gradient-to-r from-secondary-orange to-yellow-300">
              探そう
            </span>
          </h1>
          <span className="block font-bold md:text-lg">
            「近所」と「名店」に焦点を当てた新しいグルメ探しアプリ
          </span>
        </div>
        <Link
          to="/about"
          className="bg-black mt-20 text-white w-56 py-4 rounded-full block text-center text-sm font-medium flex items-center justify-center hover:bg-primary-orange transition duration-300"
        >
          <span className="block">東京横丁について知る</span>
        </Link>
      </div>
      <div className="absolute -right-6 lg:-right-8 bottom-24 md:bottom-20 transform rotate-90 flex items-center gap-x-2">
        <span className="text-2xs tracking-wider">SCROLL</span>
        <div className="overflow-hidden relative w-12 md:w-16 lg:w-20 h-0.5px bg-gray-400">
          <div className="scroll-anim absolute inset-0 h-0.5px bg-black" />
        </div>
      </div>
    </section>
  );
};
