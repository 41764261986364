import { graphql, Link, useStaticQuery } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const AboutBanner = () => {
  const data = useStaticQuery(graphql`
    {
      aboutBanner: file(relativePath: { eq: "about-banner.jpeg" }) {
        publicURL
      }
    }
  `);
  return (
    <section className="w-full px-6 xl:px-16 max-w-screen-2xl mx-auto mb-16 xl:mb-0">
      <div className="flex flex-col items-center justify-center gap-8 md:flex-row md:flex-row-reverse md:gap-0">
        <div className="w-full h-80 md:h-96 lg:h-120 xl:h-160 md:w-1/2">
          <img
            className="w-full h-full object-cover circle-transform"
            src={data.aboutBanner.publicURL}
            alt="東京横丁について　画像"
          />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-2xl font-bold tracking-wider mb-6 text-center md:text-3xl md:mb-8 lg:text-4xl">
            東京横丁について
          </h2>
          <p className="text-sm tracking-wider leading-loose text-center mb-6 md:mb-8">
            最近引っ越してきて良いお店を知りたい・・・
            <br />
            旅行先・出張先で美味しいご飯が食べたい・・・
            <br />
            今みんなが注目しているお店を知りたい・・・
            <br />
            <br />
            わたしたちはこんな思いを叶える為、従来のグルメアプリにはない
            <br />
            「近所」と「名店」に焦点を当てたグルメ探しを提供します。
          </p>
          <Link className="mt-20 pointer-events-none text-white bg-black w-56 mx-auto py-4 rounded-full block text-center text-sm font-medium">
            リリース準備中
          </Link>
        </div>
      </div>
    </section>
  );
};
