import React, { useState, useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { MobileNav } from "./MobileNav";

export const Header = () => {
  const data = useStaticQuery(graphql`
    {
      logoType: file(relativePath: { eq: "logo-type.svg" }) {
        publicURL
      }
    }
  `);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  });
  return (
    <header className="z-50 w-screen fixed inset-x-0 top-0 bg-white px-6 py-4 md:px-12 lg:px-16 lg:py-6 xl:px-32">
      <div className="flex items-center justify-between">
        <Link className="z-50" to="/">
          <img
            className="w-32 lg:w-40"
            src={data.logoType.publicURL}
            alt="東京横丁　ロゴ"
          />
        </Link>
        {/* mobile humburger menu */}
        <button
          onClick={() => setOpen(!open)}
          className="lg:hidden flex flex-col gap-y-1.5 z-50"
          aria-label="メニューボタン"
        >
          <div
            className={`${
              open ? "rotate-45 translate-y-1" : ""
            } w-6 h-0.5 rounded-full transform duration-500 bg-black`}
          />
          <div
            className={`${
              open ? "-rotate-45 -translate-y-1" : ""
            } w-6 h-0.5 rounded-full transform duration-500 bg-black`}
          />
        </button>
        <MobileNav open={open} setOpen={setOpen} />
        <nav className="hidden lg:block text-sm font-bold tracking-widest">
          <ul className="flex gap-x-6 xl:gap-x-12">
            <li className="group transform duration-500">
              <Link to="/about">東京横丁について</Link>
              <div className="mt-2 h-0.5 w-0 bg-gradient-to-r from-secondary-orange to-yellow-300 rounded-full group-hover:w-full transform duration-500" />
            </li>
            <li className="group transform duration-500">
              <Link to="/company">会社概要</Link>
              <div className="mt-2 h-0.5 w-0 bg-gradient-to-r from-secondary-orange to-yellow-300 rounded-full group-hover:w-full transform duration-500" />
            </li>
            <li className="group transform duration-500">
              <Link to="/career">採用情報</Link>
              <div className="mt-2 h-0.5 w-0 bg-gradient-to-r from-secondary-orange to-yellow-300 rounded-full group-hover:w-full transform duration-500" />
            </li>
            <li className="group transform duration-500">
              <Link to="/blog">横丁ブログ</Link>
              <div className="mt-2 h-0.5 w-0 bg-gradient-to-r from-secondary-orange to-yellow-300 rounded-full group-hover:w-full transform duration-500" />
            </li>
            <li className="group transform duration-500">
              <Link to="/contact">お問い合わせ</Link>
              <div className="mt-2 h-0.5 w-0 bg-gradient-to-r from-secondary-orange to-yellow-300 rounded-full group-hover:w-full transform duration-500" />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};
