import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { PageTitle } from "./PageTitle";

export const Career = () => {
  const data = useStaticQuery(graphql`
    {
      externalLink: file(relativePath: { eq: "external-link.svg" }) {
        publicURL
      }
      careerBanner: file(relativePath: { eq: "career-banner.jpeg" }) {
        publicURL
      }
    }
  `);
  return (
    <section className="px-2 md:px-8 xl:px-0 max-w-7xl mx-auto mb-24 md:mb-32 xl:mb-40">
      <PageTitle title="採用情報" />
      <div className="bg-white box-shadow pt-16 pb-2 px-2 rounded-news bg-opacity-80 md:py-20 lg:py-24">
        <div className="">
          <div className="max-w-6xl mx-auto md:flex items-center gap-x-8 md:px-5 lg:px-7 xl:px-0">
            <div className="px-5 w-full mb-16 md:mb-0 md:px-0">
              <h2 className="text-lg font-bold tracking-wider leading-relaxed mb-8 lg:text-2xl">
                一緒に東京横丁を盛り上げよう
              </h2>
              <p className="text-sm tracking-wider leading-loose font-medium mb-6 lg:w-80">
                わたしたちの理念や、目指す世界に共感してくださる仲間を常に募集しています。
                <br />
                みなさまにお会いできることを楽しみにしています。
              </p>
              <Link
                to="/career"
                className="text-white bg-black w-56 py-4 rounded-full flex items-center justify-center gap-x-4 text-center text-sm font-medium hover:bg-primary-orange transition duration-300"
              >
                <span>応募はこちら</span>
                <span>
                  <img
                    src={data.externalLink.publicURL}
                    alt="外部サイト　リンク"
                  />
                </span>
              </Link>
            </div>
            <div className="w-full h-56 md:h-64 lg:h-80 xl:h-96">
              <img
                className="w-full h-full object-cover rounded-news"
                src={data.careerBanner.publicURL}
                alt="採用情報　画像"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
