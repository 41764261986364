import { Link } from "gatsby";
import React from "react";

export const MobileNav = ({ open, setOpen }) => {
  return (
    <div
      className={`${
        open ? "" : "max-h-0 opacity-0 pointer-events-none"
      } fixed inset-0 w-screen h-screen bg-white z-40 transform duration-500`}
    >
      <nav className="mt-40">
        <ul className="flex flex-col gap-y-12 items-center justify-center font-bold tracking-wider text-lg">
          <li>
            <Link to="/">HOME</Link>
          </li>
          <li>
            <Link to="/about">東京横丁について</Link>
          </li>
          <li>
            <Link to="/company">会社概要</Link>
          </li>
          <li>
            <Link to="/career">採用情報</Link>
          </li>
          <li>
            <Link to="/blog">横丁ブログ</Link>
          </li>
          <li>
            <Link to="/contact">お問い合わせ</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};
