import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

export const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      logoType: file(relativePath: { eq: "logo-type.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <footer className="px-8 w-full flex flex-col items-center justify-center mb-16">
      <Link to="/" className="mb-8">
        <img
          className="w-28 lg:w-32"
          src={data.logoType.publicURL}
          alt="東京横丁　ロゴ"
        />
      </Link>
      <ul className="mb-8 flex flex-wrap items-center justify-center gap-x-6 gap-y-2 text-xs lg:text-sm lg:gap-x-10 font-medium tracking-wider">
        <li>
          <Link to="/about">東京横丁について</Link>
        </li>
        <li>
          <Link to="/company">会社概要</Link>
        </li>
        <li>
          <Link to="/career">採用情報</Link>
        </li>
        <li>
          <Link to="/blog">横丁ブログ</Link>
        </li>
        <li>
          <Link to="/contact">お問い合わせ</Link>
        </li>
        <li>
          <Link to="/terms">利用規約</Link>
        </li>
        <li>
          <Link to="/privacy">プライバシーポリシー</Link>
        </li>
      </ul>
      <span className="text-2xs">©︎ 2021 Tokyo Yokocho.</span>
    </footer>
  );
};
