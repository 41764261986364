import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

export const CareerBanner = () => {
  const data = useStaticQuery(graphql`
    {
      careerBanner: file(relativePath: { eq: "career-banner.jpeg" }) {
        publicURL
      }
    }
  `);
  return (
    <section className="w-full px-6 xl:px-16 max-w-screen-2xl mx-auto mb-16 md:mb-24">
      <div className="flex flex-col items-center justify-center gap-8 md:flex-row md:items-end md:gap-0">
        <div className="w-full h-80 md:h-96 lg:h-120 xl:h-160 md:w-1/2">
          <img
            className="w-full h-full object-cover circle-transform-2"
            src={data.careerBanner.publicURL}
            alt="採用情報について　画像"
          />
        </div>
        <div className="md:w-1/2 md:pb-8">
          <h3 className="text-2xl font-bold tracking-wider mb-6 text-center md:text-3xl md:mb-8 lg:text-4xl">
            採用情報
          </h3>
          <p className="text-sm tracking-wider leading-loose text-center mb-6 md:mb-8">
            わたしたちの理念や目指す世界に
            <br />
            共感してくださる仲間を常に募集しています。
            <br />
            みなさまにお会いできることを楽しみにしています。
            <br />
          </p>
          <Link
            to="/career"
            className="bg-black mt-20 text-white w-56 mx-auto py-4 rounded-full block text-center text-sm font-medium flex items-center justify-center hover:bg-primary-orange transition duration-300"
          >
            <span className="block">採用情報へ</span>
          </Link>
        </div>
      </div>
    </section>
  );
};
