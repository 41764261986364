import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

export const BlogBanner = () => {
  const data = useStaticQuery(graphql`
    {
      allMicrocmsBlog(limit: 3, sort: { fields: [createdAt], order: DESC }) {
        edges {
          node {
            blogId
            featuredImage {
              url
            }
            date(formatString: "Y年MM月DD日")
            category {
              id
              name
            }
            title
            createdAt(formatString: "Y-MM-DD")
          }
        }
      }
    }
  `);
  return (
    <section className="px-6 md:px-16 lg:px-24 xl:px-0 max-w-5xl mx-auto mb-24 md:mb-32 xl:mb-40">
      <div className="mb-8 md:mb-12 lg:mb-16">
        <h4 className="text-xl font-bold tracking-wider text-center md:text-left md:text-2xl lg:text-3xl">
          横丁ブログ
        </h4>
      </div>
      <div className="gap-4 lg:gap-8 grid grid-cols-2 md:grid-cols-3 xl:gap-12 mb-16">
        {data.allMicrocmsBlog.edges.map((edge) => {
          const post = edge.node;
          const category = post.category[0].name;
          return (
            <React.Fragment key={post.blogId}>
              <Link
                to={`/blog/${post.blogId}`}
                className="w-full h-64 lg:h-80 xl:h-96 bg-white filter drop-shadow-lg rounded-2xl lg:rounded-blog group hover:drop-shadow-2xl transform duration-500"
              >
                <img
                  className="w-full h-1/2 rounded-t-2xl lg:rounded-blog-img object-cover"
                  src={post.featuredImage.url}
                  alt={`${post.title} 画像`}
                />
                <div className="pt-2 px-2 pb-2 lg:p-6">
                  <div className="w-full flex items-center justify-between mb-2">
                    <span
                      className={`${
                        category === "お知らせ"
                          ? "bg-primary-purple"
                          : `${
                              category === "メディア掲載"
                                ? "bg-primary-blue"
                                : `${
                                    category === "ブログ"
                                      ? "bg-primary-orange"
                                      : ""
                                  }`
                            }`
                      } rounded-full py-1 px-2 lg:px-4 text-2xs lg:text-xs text-white font-medium`}
                    >
                      {category}
                    </span>
                    <time
                      dateTime={post.createdAt}
                      className="text-2xs lg:text-xs tracking-wider text-gray-600 font-medium"
                    >
                      {post.date}
                    </time>
                  </div>
                  <h6 className="text-xs lg:text-sm xl:text-base font-medium tracking-wider leading-relaxed group-hover:text-blue-500 transform duration-300">
                    {post.title}
                  </h6>
                </div>
              </Link>
            </React.Fragment>
          );
        })}
      </div>
      <Link
        to="/blog"
        className="bg-black mt-20 text-white w-56 ml-auto py-4 rounded-full block text-center text-sm font-medium flex items-center justify-center hover:bg-primary-orange transition duration-300"
      >
        <span className="block">ブログ一覧へ</span>
      </Link>
    </section>
  );
};
