import React from "react";
import { PageTitle } from "./PageTitle";

export const About = () => {
  return (
    <section className="px-2 md:px-8 xl:px-0 max-w-7xl mx-auto mb-24 md:mb-32 xl:mb-40">
      <PageTitle title="東京横丁について" />
      <div className="bg-white box-shadow py-16 px-4 rounded-news bg-opacity-80 md:py-20 lg:py-24">
        <div className="mb-12 md:mb-24 lg:mb-32">
          <span className="block text-xl text-center font-bold tracking-wide mb-2 lg:text-2xl lg:mb-4">
            Mission
          </span>
          <div className="w-8 h-1 rounded-full bg-black mx-auto mb-6 lg:mb-16" />
          <h2 className="text-center text-3xl tracking-wider font-bold mb-4 px-2 lg:text-5xl lg:mb-12">
            かゆい所に手が届くお店探し
          </h2>
          <p className="text-sm text-center font-medium tracking-wider leading-extra-loose lg:text-base">
            高くて美味しいは当たり前。
            <br />
            お店探しでこんな経験はありませんか？
            <br />
            口コミ、点数、おおよその予算だけを手がかりに
            <br />
            来店した結果、思ったより満足できなかった。
            <br />
            そんな経験あると思います。
            <br />
            グルメサイト、GoogleMaps、Instagramはお店探しの定番です。
            <br />
            しかし、本当にコスパの良いお店は地元の人しか知らない。
            <br />
            もしあなたが渋谷でご飯を食べに行く予定が会った時
            <br />
            渋谷に住んでいるグルメ好きの人に美味しいお店を聞けたら
            <br />
            それは価値のある情報になりますよね。
            <br />
            そんなネット上では見つからないかゆい所に手が届くお店を
            <br />
            グルメ好きの『フーディー』が教えてくれます。
            <br />
          </p>
        </div>
        <div className="">
          <span className="block text-xl text-center font-bold tracking-wide mb-2 lg:text-2xl lg:mb-4">
            Vision
          </span>
          <div className="w-8 h-1 rounded-full bg-black mx-auto mb-6 lg:mb-16" />
          <h3 className="text-center text-3xl tracking-wider font-bold mb-4 px-2 lg:text-5xl lg:mb-12">
            食探しに新たな価値を
          </h3>
          <p className="text-sm text-center font-medium tracking-wider leading-extra-loose lg:text-base">
            あなたのお気に入りのお店が一番の価値です。
            <br />
            お店選びで悩んでいる人へ良質な情報をお届けします。
            <br />
          </p>
        </div>
      </div>
    </section>
  );
};
