import React from "react";
import { PageTitle } from "./PageTitle";

export const Privacy = () => {
  return (
    <section className="px-2 md:px-8 xl:px-0 max-w-7xl mx-auto mb-24 md:mb-32 xl:mb-40">
      <PageTitle title="アプリケーション・プライバシーポリシー" />
      <div className="bg-white box-shadow py-16 px-4 rounded-news bg-opacity-80 md:py-20 lg:py-24">
        <div className="max-w-3xl mx-auto">
          <p className="terms-text">
            当社が提供するスマートフォン用アプリ（以下「本アプリ」といいます。）における利用者情報の取扱いに関し、以下のとおりアプリケーション・プライバシーポリシー（以下「本ポリシー」といいます。）を設定します。本ポリシーは、当社の定めるプライバシーポリシーとともに適用されるものとしますが、当該プライバシーポリシーと本ポリシーが矛盾抵触する場合には、本ポリシーが優先するものとします。
          </p>
          <h3 className="terms-subtitle pt-2">
            1. 情報を取得するアプリケーション提供者の名称、連絡先
          </h3>
          <p className="terms-text">
            名称：株式会社プリベント
            <br />
            連絡先：
            <a className="uderline" href="mailto:info@tokyo-yokocho.jp">
              info@tokyo-yokocho.jp
            </a>
          </p>
          <h3 className="terms-subtitle pt-2">
            2. 取得される利用者情報と利用目的
          </h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              お客様の入力により取得するもの
              <br />
              お客様は、本アプリ上において会員登録を行い又は当社のマッチングサービス（以下「本サービス」といいます。）の利用を申し込む際に以下の利用者情報を入力します。これにより当社は利用者情報を取得します。
            </li>
            <div className="table-privacy border text-sm lg:text-base mb-2">
              <div className="flex border-b">
                <div className="border-r w-1/2 lg:w-2/5">
                  <span className="block text-center p-2">利用者情報</span>
                </div>
                <div className="w-1/2 lg:w-3/5">
                  <span className="block text-center p-2">利用目的</span>
                </div>
              </div>
              <div className="flex border-b">
                <div className="border-r w-1/2 lg:w-2/5">
                  <span className="block px-4 py-2">
                    ＜必須項目＞
                    <br />
                    ・氏名
                    <br />
                    ・住所
                    <br />
                    ・性別
                    <br />
                    ・生年月日
                    <br />
                    ・電話番号
                    <br />
                    ・電子メールアドレス
                    <br />
                    ・職業
                    <br />
                    ・顔写真
                    <br />
                    <br />
                    ＜任意で選択可能な入力項目＞
                    <br />
                    ・居住地
                    <br />
                    ・勤務地
                    <br />
                    ・出身地
                    <br />
                    ・血液型
                    <br />
                    ・身長
                    <br />
                    ・趣味
                    <br />
                    ・食べ物の嗜好
                    <br />
                    ・休日
                    <br />
                    ・職業
                    <br />
                    ・喫煙の有無
                    <br />
                    ・飲酒の有無
                    <br />
                    ・同居人の有無
                    <br />
                    ・結婚歴
                    <br />
                    ・学歴
                  </span>
                </div>
                <div className="w-1/2 lg:w-3/5">
                  <span className="block px-4 py-2">
                    ＜必須項目＞
                    <br />
                    ・本人確認及び年齢確認のため
                    <br />
                    ・当社が本サービスを提供するため
                    <br />
                    ・当社本サービスに関してお客様に連絡を行うため
                    <br />
                    ・当社の本サービスの改善又は開発に役立てるため
                    <br />
                    ・お客様からの問合せや要望に対応するため
                    <br />
                    ・お客様に対して有益な情報を提供するため
                    <br />
                    ・当社が提供するサービス、キャンペーンを案内し、その他マーケティング活動に利用するため
                    <br />
                    ・当社の広告の最適化のため
                    <br />
                    ・統計資料を作成するため
                    <br />
                    ・不適切なサービスの利用行為を防止するため
                  </span>
                </div>
              </div>
            </div>
            <li className="terms-text">
              当社の本サービスの提供に際してお客様からの情報提供に基づき取得されるもの
              <br />
              当社は、お客様が当社の本サービスを利用される際に、お客様からの情報提供により以下の利用者情報を取得します。
            </li>
            <div className="table-privacy border text-sm lg:text-base mb-2">
              <div className="flex border-b">
                <div className="border-r w-1/2 lg:w-2/5">
                  <span className="block text-center p-2">利用者情報</span>
                </div>
                <div className="w-1/2 lg:w-3/5">
                  <span className="block text-center p-2">利用目的</span>
                </div>
              </div>
              <div className="flex border-b">
                <div className="border-r w-1/2 lg:w-2/5">
                  <span className="block px-4 py-2">該当ありません。</span>
                </div>
                <div className="w-1/2 lg:w-3/5">
                  <span className="block px-4 py-2"></span>
                </div>
              </div>
            </div>
            <li className="terms-text">
              本アプリの自動取得によるもの
              <br />
              以下の利用者情報は、お客様が本アプリを利用される際に自動的に取得されます。
            </li>
            <div className="table-privacy border text-sm lg:text-base">
              <div className="flex border-b">
                <div className="border-r w-1/2 lg:w-2/5">
                  <span className="block text-center p-2">利用者情報</span>
                </div>
                <div className="w-1/2 lg:w-3/5">
                  <span className="block text-center p-2">利用目的</span>
                </div>
              </div>
              <div className="flex border-b">
                <div className="border-r w-1/2 lg:w-2/5">
                  <span className="block px-4 py-2">
                    ・行動履歴
                    <br />
                    ・当社のサービスの利用状況
                    <br />
                    ・端末情報（OS、機種名、ユーザーエージェント）
                    <br />
                    ・Cookie
                  </span>
                </div>
                <div className="w-1/2 lg:w-3/5">
                  <span className="block px-4 py-2">
                    ・前記(1)の利用目的
                    <br />
                    ・当社のサービスの利用状況の分析のため
                    <br />
                    ・当社のサービスの改善又は開発に役立てるため
                  </span>
                </div>
              </div>
            </div>
          </ol>
          <h3 className="terms-subtitle pt-2">
            3. 情報収集モジュールによる取得
          </h3>
          <p className="terms-text">
            以下の利用者情報は、本アプリケーション内に組み込まれた下記の情報収集モジュールにて取得します。情報収集モジュールとは、第三者が提供するプログラムであって、利用者情報を取得・解析するための機能を有するものをいいます。
          </p>
          <ol className="terms-numbering">
            <li className="terms-text">
              情報収集モジュール名：Google Analytics
            </li>
            <li className="terms-text">情報収集モジュール提供者：Google</li>
            <li className="terms-text">
              取得する利用者情報：お客様による本アプリの利用状況
            </li>
            <li className="terms-text">
              【情報収集モジュール提供者による利用者情報の利用：利用者情報はGoogleの管理するサーバシステムに格納されます。なお、Googleは当該利用者情報を、Googleが定める利用目的の範囲で利用します。Google
              Analyticsの詳細及びGoogleが定める利用目的及び利用者情報の第三者提供の有無は以下をご参照下さい。
              <br />
              <a
                href="https://policies.google.com/privacy"
                className="underline"
              >
                https://policies.google.com/privacy
              </a>
            </li>
          </ol>
          <h3 className="terms-subtitle">4. 利用者情報の第三者提供</h3>
          <p className="terms-text">
            当社は、法令に定める場合又は本人の同意がある場合を除き、利用者情報（個人情報の保護に関する法律（以下「個人情報保護法」といいます。）第16条第3項に定める個人データを含みます。）を第三者に送信し又は提供することはありません。但し、前項に定める情報収集モジュールにより同項記載の利用者情報が取得されることがあるものとします。
          </p>
          <h3 className="terms-subtitle">
            5. 本ポリシーによる通知、公表又は同意取得
          </h3>
          <p className="terms-text">
            お客様は本アプリをインストールする際に、本ポリシーの内容を確認し、同意を付与する機会が与えられます。本アプリ及び当社のサービスは本ポリシーをご確認頂き、内容を理解した上でご利用下さい。また、本ポリシーは本アプリの【設定ボタン⇒プライバシーポリシー】にて公表しています。
          </p>
          <h3 className="terms-subtitle">6. 利用者情報の利用停止等</h3>
          <p className="terms-text">
            お客様は、利用者情報のうち、個人情報保護法第16条第4項に定める保有個人データについて、以下に定めるとおり利用停止等ができるものとします。
          </p>
          <h3 className="terms-subtitle">＜保有個人データの開示＞</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              当社は、お客様から保有個人データの開示を求められたときは、お客様に対し、遅滞なくこれを開示します。但し、開示することにより次のいずれかに該当する場合は、その全部又は一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、保有個人データの開示に際しては、1件あたり1,000円の手数料を申し受けます。
              <ol className="terms-subnumbering">
                <li className="terms-text">
                  お客様又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
                </li>
                <li className="terms-text">
                  当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
                </li>
                <li className="terms-text">
                  その他法令に違反することとなる場合
                </li>
              </ol>
            </li>
            <li className="terms-text">
              会員登録されたお客様は本無料サービスを、本規約に従って無償にて利用することができます。また、会員登録されたお客様は、当社との間で本有料サービス利用契約を締結することにより、本有料サービスを利用することができます。
            </li>
            <li className="terms-text">
              当社は、本サービスを利用するお客様に対し、本規約の定める条件に従い、本アプリの譲渡不能で非独占的な使用権を許諾します。
            </li>
            <li className="terms-text">
              当社は、日本語により本アプリ及び本サービスを提供するものとします。
            </li>
            <li className="terms-text">
              お客様は、本サービスを受けるに当たり、本アプリ又は本ウェブサイトにおいて表示される利用方法又は注意事項を全て遵守するものとします。
            </li>
            <li className="terms-text">
              当社は、本サービスの内容を変更し又は本サービスを終了することがあるものとします。
            </li>
          </ol>
          <h3 className="terms-subtitle">＜保有個人データの訂正及び削除＞</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              お客様は、当社の保有する自己の保有個人データの内容が事実ではない場合には、当社が定める手続により、当社に対して保有個人データの訂正、追加又は削除（以下「訂正等」といいます。）を請求することができます。
            </li>
            <li className="terms-text">
              当社は、お客様から前項の請求を受けてこれに応じる必要があると判断した場合には、遅滞なく、当該保有個人データの訂正等を行うものとします。
            </li>
            <li className="terms-text">
              当社は、前項の規定に基づき訂正等を行った場合、又は訂正等を行わない旨の決定をしたときは、遅滞なく、これをお客様に通知します。
            </li>
          </ol>
          <h3 className="terms-subtitle">＜保有個人データの利用停止等＞</h3>
          <ol className="terms-numbering">
            <li className="terms-text">
              当社は、お客様から、法令に定める事由により、(i)保有個人データの利用の停止若しくは消去（以下「利用停止等」といいます。）又は(ii)当社がお客様の同意を得て保有個人データを第三者に提供している場合における当社による当該保有個人データの第三者への提供の停止（以下「第三者提供停止」といいます。）を求められた場合には、遅滞なく必要な調査を行います。
            </li>
            <li className="terms-text">
              当社は、前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該保有個人データの利用停止等又は第三者提供停止を行います。
            </li>
            <li className="terms-text">
              当社は、前項の規定に基づき利用停止等若しくは第三者提供停止を行った場合、又は利用停止等若しくは第三者提供停止を行わない旨の決定をしたときは、遅滞なく、これをお客様に通知します。
            </li>
            <li className="terms-text">
              前二項にかかわらず、利用停止等又は第三者提供停止に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、お客様の権利利益を保護するために必要なこれに代わるべき措置をとれることができる場合は、この代替策を講じるものとします。
            </li>
          </ol>
          <h3 className="terms-subtitle">
            7.
            本アプリをアンインストールした場合のデータの保存期間、その後の処理等
          </h3>
          <p className="terms-text">
            当社が第2項に基づきお客様の利用者情報を取得し、かつこれらを削除することなく保存している場合、お客様が本アプリをアンインストールことにより、これらの利用者情報は即座に消去されます。
          </p>
          <h3 className="terms-subtitle">8. 本ポリシーの変更</h3>
          <p className="terms-text">
            当社は、本アプリのバージョンアップに伴い、本ポリシーを変更する場合があります。変更内容は、本ポリシー変更後最初にお客様が本アプリを利用される際に、本アプリ上に表示されますので、内容をご確認下さい。
          </p>
          <h3 className="terms-subtitle">9. 当社の名称、住所、代表者氏名 </h3>
          <p className="terms-text">
            当社の名称、住所及び代表者氏名は以下のとおりです。
            <br />
            名称：株式会社プリベント
            <br />
            住所：〒223-0057　神奈川県横浜市港北区新羽町423ー1
            <br />
            代表取締役：金山　貴司
          </p>
          <h3 className="terms-subtitle">9. お問合せ窓口</h3>
          <p className="terms-text">
            利用者情報の取扱いについての苦情、相談又は問合せは、以下の利用者情報お問合せ窓口までご連絡下さい。
            <br />
            株式会社プリベント
            <br />
            個人情報お問合せ窓口担当者
            <br />
            <a href="mailto:inquiry@tokyo-yokocho.jp" className="underline">
              inquiry@tokyo-yokocho.jp
            </a>
          </p>
          <div className="mt-24 lg:mt-40 flex flex-col items-end">
            <span className="block text-sm lg:text-base tracking-wider">
              2022年6月24日制定
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
