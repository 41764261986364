import React from "react";

export const PageTitle = ({ title }) => {
  return (
    <div className="mt-32 mb-16 lg:mt-40 lg:mb-24 ">
      <h1 className="text-xl font-bold tracking-widest text-center lg:text-2xl">
        {title}
      </h1>
    </div>
  );
};
