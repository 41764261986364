import React, { useState } from "react";
import { navigate } from "gatsby";

import { PageTitle } from "./PageTitle";

export const Contact = () => {
  const [values, setValues] = useState({
    company: "",
    nameKanji: "",
    nameKana: "",
    tel: "",
    email: "",
    purpose: "",
    content: "",
  });

  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("/api/form", {
        method: "POST",
        body: JSON.stringify({ values }),
      });
      console.log("response.status", response.status);
      if (response.status === 200) {
        console.log("entered");
        navigate("/contact/success");
      } else {
        setError("入力欄が正しくありません。");
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  return (
    <section className="px-2 md:px-8 xl:px-0 max-w-7xl mx-auto mb-24 md:mb-32 xl:mb-40">
      <PageTitle title="お問い合わせ" />
      <div className="bg-white box-shadow py-16 px-4 rounded-news bg-opacity-80 md:pt-20 lg:pt-24">
        <div className="px-2 max-w-2xl mx-auto lg:px-0">
          <form onSubmit={handleSubmit}>
            <ul className="flex flex-col gap-y-8 text-sm mb-16 md:gap-y-10">
              <li className="md:flex items-center justify-between">
                <div className="flex-shrink-0">
                  <label htmlFor="company">会社名 / 団体名</label>
                </div>
                <input
                  className="mt-2 text-sm tracking-wider bg-gray-100 w-full px-4 py-2 rounded-full md:mt-0 md:w-2/3"
                  type="text"
                  name="company"
                  id="company"
                  onChange={(e) =>
                    setValues({ ...values, company: e.target.value })
                  }
                  value={values.company}
                />
              </li>
              <li className="md:flex items-center justify-between">
                <div className="flex-shrink-0">
                  <label htmlFor="nameKanji">名前（漢字）</label>
                  <span className="ml-2 text-white bg-secondary-orange py-0.5 px-4 rounded-full text-xs font-medium">
                    必須
                  </span>
                </div>
                <input
                  className="mt-2 text-sm tracking-wider bg-gray-100 w-full px-4 py-2 rounded-full md:mt-0 md:w-2/3"
                  type="text"
                  name="nameKanji"
                  id="nameKanji"
                  onChange={(e) =>
                    setValues({ ...values, nameKanji: e.target.value })
                  }
                  value={values.nameKanji}
                  required
                />
              </li>
              <li className="md:flex items-center justify-between">
                <div className="flex-shrink-0">
                  <label htmlFor="nameKana">名前（ひらがな）</label>
                  <span className="ml-2 text-white bg-secondary-orange py-0.5 px-4 rounded-full text-xs font-medium">
                    必須
                  </span>
                </div>
                <input
                  className="mt-2 text-sm tracking-wider bg-gray-100 w-full px-4 py-2 rounded-full md:mt-0 md:w-2/3"
                  type="text"
                  name="nameKana"
                  id="nameKana"
                  value={values.nameKana}
                  onChange={(e) =>
                    setValues({ ...values, nameKana: e.target.value })
                  }
                  required
                />
              </li>
              <li className="md:flex items-center justify-between">
                <div className="flex-shrink-0">
                  <label htmlFor="tel">電話番号</label>
                </div>
                <input
                  className="mt-2 text-sm tracking-wider bg-gray-100 w-full px-4 py-2 rounded-full md:mt-0 md:w-2/3"
                  type="tel"
                  name="tel"
                  id="tel"
                  value={values.tel}
                  onChange={(e) =>
                    setValues({ ...values, tel: e.target.value })
                  }
                />
              </li>
              <li className="md:flex items-center justify-between">
                <div className="flex-shrink-0">
                  <label htmlFor="email">メールアドレス</label>
                  <span className="ml-2 text-white bg-secondary-orange py-0.5 px-4 rounded-full text-xs font-medium">
                    必須
                  </span>
                </div>
                <input
                  className="mt-2 text-sm tracking-wider bg-gray-100 w-full px-4 py-2 rounded-full md:mt-0 md:w-2/3"
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                  required
                />
              </li>
              <li className="mb-4 md:flex justify-between">
                <div className="flex-shrink-0">
                  <label htmlFor="purpose">お問い合わせ種別</label>
                  <span className="ml-2 text-white bg-secondary-orange py-0.5 px-4 rounded-full text-xs font-medium">
                    必須
                  </span>
                </div>
                <div
                  className="md:w-2/3"
                  onChange={(e) =>
                    setValues({ ...values, purpose: e.target.value })
                  }
                >
                  <div className="flex items-center gap-x-2 mt-4 md:mt-0 ">
                    <input
                      type="radio"
                      name="purpose"
                      id="media"
                      value="東京横丁への取材依頼、プレス関連"
                    />
                    <label className="text-sm tracking-wider" htmlFor="media">
                      東京横丁への取材依頼、プレス関連
                    </label>
                  </div>
                  <div className="flex items-center gap-x-2 mt-2">
                    <input
                      type="radio"
                      name="purpose"
                      id="career"
                      value="採用関連"
                    />
                    <label className="text-sm tracking-wider" htmlFor="career">
                      採用関連
                    </label>
                  </div>
                  <div className="flex items-center gap-x-2 mt-2">
                    <input
                      type="radio"
                      name="purpose"
                      id="promotion"
                      value="プロモーション・スポンサー関連"
                    />
                    <label
                      className="text-sm tracking-wider"
                      htmlFor="promotion"
                    >
                      プロモーション・スポンサー関連
                    </label>
                  </div>
                  <div className="flex items-center gap-x-2 mt-2">
                    <input
                      type="radio"
                      name="purpose"
                      id="other"
                      value="その他のお問い合わせ・ご意見・ご要望"
                    />
                    <label className="text-sm tracking-wider" htmlFor="other">
                      その他のお問い合わせ・ご意見・ご要望
                    </label>
                  </div>
                </div>
              </li>
              <li className="md:flex justify-between">
                <div className="flex-shrink-0 mt-2">
                  <label htmlFor="content">お問い合わせ内容</label>
                  <span className="ml-2 text-white bg-secondary-orange py-0.5 px-4 rounded-full text-xs font-medium">
                    必須
                  </span>
                </div>
                <textarea
                  className="block rounded-3xl tracking-wider bg-gray-100 w-full p-4 mt-2 md:mt-0 md:w-2/3"
                  name="content"
                  id="content"
                  rows="8"
                  maxlength="4000"
                  placeholder="最大4000文字まで"
                  value={values.content}
                  onChange={(e) =>
                    setValues({ ...values, content: e.target.value })
                  }
                  required
                ></textarea>
              </li>
            </ul>
            {error ? (
              <div className="px-3 py-3">
                <h4 className="text-red-500">{error}</h4>
              </div>
            ) : null}
            <button
              type="submit"
              className="py-4 bg-black text-white rounded-full block text-center w-64 text-sm font-medium mx-auto hover:bg-secondary-orange transition duration-300"
            >
              送信
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};
