import React from "react";
import { PageTitle } from "./PageTitle";

export const Company = () => {
  return (
    <section className="px-2 md:px-8 xl:px-0 max-w-7xl mx-auto mb-24 md:mb-32 xl:mb-40">
      <PageTitle title="会社概要" />
      <div className="bg-white box-shadow py-16 px-4 rounded-news bg-opacity-80 md:pt-20 lg:pt-24">
        <div className="mb-8">
          <span className="block text-xl text-center font-bold tracking-wide mb-2 lg:text-2xl lg:mb-4">
            Company
          </span>
          <div className="w-8 h-1 rounded-full bg-black mx-auto mb-12 lg:mb-24" />
          <div className="px-4 max-w-4xl mx-auto lg:px-0">
            <div className="grid grid-cols-1 md:grid-rows-2 md:grid-cols-2 gap-x-8 text-xs md:text-sm font-medium tracking-widest mb-16 md:mb-24">
              <div className="flex justify-between border-t border-b border-gray-200 py-4 md:border-none">
                <span className="flex-shrink-0 whitespace-no-wrap block">
                  社　名
                </span>
                <span className="w-3/4 block">株式会社プリベント</span>
              </div>
              <div className="flex justify-between border-b border-gray-200 py-4 md:border-none">
                <span className="flex-shrink-0 whitespace-no-wrap block">
                  設　立
                </span>
                <span className="w-3/4 block">2015年11月</span>
              </div>
              <div className="flex justify-between border-b border-gray-200 py-4 md:border-none">
                <span className="flex-shrink-0 whitespace-no-wrap block">
                  代　表
                </span>
                <span className="w-3/4 block">金山 貴司</span>
              </div>
              <div className="flex justify-between border-b border-gray-200 py-4 md:border-none">
                <span className="flex-shrink-0 whitespace-no-wrap block">
                  所在地
                </span>
                <span className="w-3/4 block">
                  東京都杉並区和泉2ー46ー10 シエルテラス1Ｆ
                </span>
              </div>
              <div className="hidden md:block invisible pointer-events-none flex justify-between border-b border-gray-200 py-4 md:border-none">
                <span className="flex-shrink-0 whitespace-no-wrap block">
                  資本金
                </span>
                <span className="w-3/4 block">100万円</span>
              </div>
              <div className="flex justify-between border-b border-gray-200 py-4 md:border-none">
                <span className="flex-shrink-0 whitespace-no-wrap block">
                  事業内容
                </span>
                <span className="w-3/4 block">
                  スマートフォン向けアプリケーションの開発・運用
                </span>
              </div>
            </div>
            <div className="w-full">
              <div className="flex items-end justify-center w-full h-96 overflow-hidden rounded-news mb-12">
                <iframe
                  className="w-full h-140"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.005097375553!2d139.6464955156919!3d35.67687648019525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f25516847877%3A0x8f07ec3981f43680!2z44CSMTY4LTAwNjMg5p2x5Lqs6YO95p2J5Lim5Yy65ZKM5rOJ77yS5LiB55uu77yU77yW4oiS77yR77yQIDFm!5e0!3m2!1sja!2sjp!4v1636534620795!5m2!1sja!2sjp"
                  allowfullscreen=""
                  loading="lazy"
                  title="company-map"
                ></iframe>
              </div>
              <a
                href="https://goo.gl/maps/MWs17VEieWRy6HDX9"
                target="_blank"
                rel="noreferrer"
                className="bg-white border-black border w-64 mx-auto py-4 rounded-full block text-center text-sm font-medium hover:text-white hover:bg-black transform duration-300"
              >
                GoogleMap で見る
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
